<template>
  <div class="buttons is-grouped" data-test-id="date-picker-button">
    <button
      class="button"
      :class="{ 'has-text-info': dateText }"
      @click="$emit('toggleFilter')"
    >
      <span class="icon">
        <i class="fal fa-calendar-alt"></i>
      </span>
    </button>
    <span class="button" v-if="isActive">
      <span @click="$emit('toggleFilter')">
        {{ dateText }}
      </span>
      <a class="icon has-text-danger" @click="$emit('clearFilter')">
        <i class="fal fa-times"></i>
      </a>
    </span>
  </div>
</template>

<script>
import { format } from '../../../utils/date'
export default {
  name: 'DatePickerButton',
  props: {
    to: {
      type: String,
      required: false,
      default: () => null
    },
    from: {
      type: String,
      required: false,
      default: () => null
    }
  },
  computed: {
    isActive() {
      return this.from && this.to
    },
    dateText() {
      const formatDate = (value) => format(value, 'MMM M, YY')
      return `${formatDate(this.from)}—${formatDate(this.to)}`
    }
  }
}
</script>
