import moment from 'moment'

export const format = (date, format) => {
  return moment(new Date(date)).format(format)
}

export const unix = date => {
  return moment(new Date(date)).valueOf()
}

export const fromNow = (date, suffix) => {
  return moment(new Date(date)).fromNow(suffix)
}
